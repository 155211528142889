import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import CourierPage from '../components/CourierPage.vue'
import CarrierPage from '../components/CarrierPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/courier',
    name: 'CourierPage',
    component: CourierPage
  },
  {
    path: '/carrier',
    name: 'Carrier',
    component: CarrierPage
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router