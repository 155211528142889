<template>
  <div :class="['app-container', { dark: isDarkTheme }]">
    <div class="logo-container">
      <img src="../../images/logo2.png" alt="СИНДИКАТ логотип" class="logo">
    </div>
    
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    
    <footer class="footer mt-8">
      <img src="../../images/icon.png" alt="Иконка СИНДИКАТ" class="icon">
    </footer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'App',
  setup() {
    const isDarkTheme = ref(false)

    const setTheme = (isDark) => {
      isDarkTheme.value = isDark
      document.documentElement.classList.toggle('dark', isDark)
    }

    const checkSystemThemePreference = () => {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme(true)
      } else {
        setTheme(false)
      }
    }

    onMounted(() => {
      checkSystemThemePreference()
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkSystemThemePreference)
    })

    return {
      isDarkTheme
    }
  }
}
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--card-bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.logo-container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 100%;
  height: auto;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-top: auto;
}

.icon {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}
</style>