<template>
  <div class="home-container flex-grow flex flex-col items-center justify-center">
    <h1 class="title mb-6 text-2xl sm:text-3xl font-bold text-center">Выберите направление:</h1>

    <div class="button-group flex flex-col sm:flex-row gap-4">
      <router-link to="/courier" class="home-button">
        Курьер
      </router-link>
      <router-link to="/carrier" class="home-button">
        Перевозчик
      </router-link>
      <button 
        class="home-button inactive" 
        disabled
        data-tooltip="Скоро здесь что-то будет..."
      >
        Оператор
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>

<style scoped>
.home-container {
  padding: 20px;
}

.title {
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 640px) {
  .button-group {
    flex-direction: row;
  }
}

.home-button {
  position: relative;
  background-color: var(--highlight-color);
  color: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 100%; 
  max-width: 300px;
}

@media (max-width: 639px) {
  .home-button {
    padding: 16px 32px;
    font-size: 20px;
  }
}

.home-button:hover {
  background-color: #ff5c5c;
}

.home-button.inactive {
  background-color: var(--hover-color);
  cursor: not-allowed;
}

.home-button.inactive:hover {
  background-color: var(--hover-color);
}

.home-button.inactive[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #555;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0;
  opacity: 1;
  z-index: 10;
}

.home-button.inactive[data-tooltip]:hover::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
</style>
